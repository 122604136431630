import {reject} from 'lodash';
import {resolve} from 'path';
import request from '/@/utils/request';
import dayjs from 'dayjs';

export function systemApi() {
    return {
        getProductType() {
            var prefix = import.meta.env.VITE_CACHE_PREFIX as any;
            var clean_prefix = import.meta.env.VITE_CACHE_PREFIX_CLEAN as any;
            // @ts-ignore
            var key = prefix + "_" + "_product_type";

            var clean_key = clean_prefix + "_" + "_product_type";

            window.localStorage.removeItem(clean_key);

            return new Promise((resolve, reject) => {
                if (window.localStorage.getItem(key)) {
                    return resolve(JSON.parse(<string>window.localStorage.getItem(key)))
                }

                return request({
                    url: '/currency/getProductType',
                    method: 'get',
                }).then(res => {
                    window.localStorage.setItem(key, JSON.stringify(res.data))
                    resolve(res.data)
                });
            })
        },
        getProductStatus() {
            var prefix = import.meta.env.VITE_CACHE_PREFIX as any;
            var clean_prefix = import.meta.env.VITE_CACHE_PREFIX_CLEAN as any;
            // @ts-ignore
            var key = prefix + "_" + "_product_status";

            var clean_key = clean_prefix + "_" + "_product_status";

            window.localStorage.removeItem(clean_key);

            return new Promise((resolve, reject) => {
                if (window.localStorage.getItem(key)) {
                    return resolve(JSON.parse(<string>window.localStorage.getItem(key)))
                }

                return request({
                    url: '/currency/getProductStatus',
                    method: 'get',
                }).then(res => {
                    window.localStorage.setItem(key, JSON.stringify(res.data))
                    resolve(res.data)
                });
            });
        },
        getAfterSaleStatus() {
            var prefix = import.meta.env.VITE_CACHE_PREFIX as any;
            var clean_prefix = import.meta.env.VITE_CACHE_PREFIX_CLEAN as any;
            // @ts-ignore
            var key = prefix + "_" + "after_sale_status";

            var clean_key = clean_prefix + "_" + "after_sale_status";

            window.localStorage.removeItem(clean_key);

            return new Promise((resolve, reject) => {
                if (window.localStorage.getItem(key)) {
                    return resolve(JSON.parse(<string>window.localStorage.getItem(key)))
                }

                return request({
                    url: '/currency/getAfterSaleStatus',
                    method: 'get',
                }).then(res => {
                    window.localStorage.setItem(key, JSON.stringify(res.data))
                    resolve(res.data)
                });
            });
        },

        getAfterSaleType() {
            var prefix = import.meta.env.VITE_CACHE_PREFIX as any;
            var clean_prefix = import.meta.env.VITE_CACHE_PREFIX_CLEAN as any;
            // @ts-ignore
            var key = prefix + "_" + "_after_sale_type";

            var clean_key = clean_prefix + "_" + "_after_sale_type";

            window.localStorage.removeItem(clean_key);

            return new Promise((resolve, reject) => {
                if (window.localStorage.getItem(key)) {
                    return resolve(JSON.parse(<string>window.localStorage.getItem(key)))
                }

                return request({
                    url: '/currency/getAfterSaleType',
                    method: 'get',
                }).then(res => {
                    window.localStorage.setItem(key, JSON.stringify(res.data))
                    resolve(res.data)
                });
            });
        },

        getOrderStatus() {
            var prefix = import.meta.env.VITE_CACHE_PREFIX as any;
            var clean_prefix = import.meta.env.VITE_CACHE_PREFIX_CLEAN as any;
            // @ts-ignore
            var key = prefix + "_" + "_order_status";

            var clean_key = clean_prefix + "_" + "_order_status";

            window.localStorage.removeItem(clean_key);

            return new Promise((resolve, reject) => {
                if (window.localStorage.getItem(key)) {
                    return resolve(JSON.parse(<string>window.localStorage.getItem(key)))
                }

                return request({
                    url: '/currency/getOrderStatus',
                    method: 'get',
                }).then(res => {
                    window.localStorage.setItem(key, JSON.stringify(res.data))
                    resolve(res.data)
                });
            });
        },
        getOrderStatusBadge() {
            var prefix = import.meta.env.VITE_CACHE_PREFIX as any;
            var clean_prefix = import.meta.env.VITE_CACHE_PREFIX_CLEAN as any;
            // @ts-ignore
            var key = prefix + "_order_status_badge";

            var clean_key = clean_prefix + "_order_status_badge";

            window.localStorage.removeItem(clean_key);

            return new Promise((resolve, reject) => {
                if (window.localStorage.getItem(key)) {
                    return resolve(JSON.parse(<string>window.localStorage.getItem(key)))
                }

                return request({
                    url: '/currency/getOrderStatusBadge',
                    method: 'get',
                }).then(res => {
                    window.localStorage.setItem(key, JSON.stringify(res.data))
                    resolve(res.data)
                });
            });
        },


        getOrderShippingStatus() {
            var prefix = import.meta.env.VITE_CACHE_PREFIX as any;
            var clean_prefix = import.meta.env.VITE_CACHE_PREFIX_CLEAN as any;
            // @ts-ignore
            var key = prefix + "_" + "_order_shipping_status";

            var clean_key = clean_prefix + "_" + "_order_shipping_status";

            window.localStorage.removeItem(clean_key);

            return new Promise((resolve, reject) => {
                if (window.localStorage.getItem(key)) {
                    return resolve(JSON.parse(<string>window.localStorage.getItem(key)))
                }

                return request({
                    url: '/currency/getOrderShippingStatus',
                    method: 'get',
                }).then(res => {
                    window.localStorage.setItem(key, JSON.stringify(res.data))
                    resolve(res.data)
                });
            });
        },
        getOrderPaidStatus() {
            var prefix = import.meta.env.VITE_CACHE_PREFIX as any;
            var clean_prefix = import.meta.env.VITE_CACHE_PREFIX_CLEAN as any;
            // @ts-ignore
            var key = prefix + "_" + "_order_paid_status";

            var clean_key = clean_prefix + "_" + "_order_paid_status";

            window.localStorage.removeItem(clean_key);

            return new Promise((resolve, reject) => {
                if (window.localStorage.getItem(key)) {
                    return resolve(JSON.parse(<string>window.localStorage.getItem(key)))
                }

                return request({
                    url: '/currency/getOrderPaidStatus',
                    method: 'get',
                }).then(res => {
                    window.localStorage.setItem(key, JSON.stringify(res.data))
                    resolve(res.data)
                });
            });
        },
        getOrderCashStatus() {
            var prefix = import.meta.env.VITE_CACHE_PREFIX as any;
            var clean_prefix = import.meta.env.VITE_CACHE_PREFIX_CLEAN as any;
            // @ts-ignore
            var key = prefix + "_" + "_order_cash_status";

            var clean_key = clean_prefix + "_" + "_order_cash_status";

            window.localStorage.removeItem(clean_key);

            return new Promise((resolve, reject) => {
                if (window.localStorage.getItem(key)) {
                    return resolve(JSON.parse(<string>window.localStorage.getItem(key)))
                }

                return request({
                    url: '/currency/getOrderCashStatus',
                    method: 'get',
                }).then(res => {
                    window.localStorage.setItem(key, JSON.stringify(res.data))
                    resolve(res.data)
                });
            });
        },
        getReturnStatus() {
            var prefix = import.meta.env.VITE_CACHE_PREFIX as any;
            var clean_prefix = import.meta.env.VITE_CACHE_PREFIX_CLEAN as any;
            // @ts-ignore
            var key = prefix + "_" + "_return_status";

            var clean_key = clean_prefix + "_" + "_return_status";

            window.localStorage.removeItem(clean_key);

            return new Promise((resolve, reject) => {
                if (window.localStorage.getItem(key)) {
                    return resolve(JSON.parse(<string>window.localStorage.getItem(key)))
                }

                return request({
                    url: '/currency/getReturnStatus',
                    method: 'get',
                }).then(res => {
                    window.localStorage.setItem(key, JSON.stringify(res.data))
                    resolve(res.data)
                });
            });
        },
        getReturnIsClosed() {
            var prefix = import.meta.env.VITE_CACHE_PREFIX as any;
            var clean_prefix = import.meta.env.VITE_CACHE_PREFIX_CLEAN as any;
            // @ts-ignore
            var key = prefix + "_" + "_is_closed";

            var clean_key = clean_prefix + "_" + "_is_closed";

            window.localStorage.removeItem(clean_key);

            return new Promise((resolve, reject) => {
                if (window.localStorage.getItem(key)) {
                    return resolve(JSON.parse(<string>window.localStorage.getItem(key)))
                }

                return request({
                    url: '/currency/getReturnIsClosed',
                    method: 'get',
                }).then(res => {
                    window.localStorage.setItem(key, JSON.stringify(res.data))
                    resolve(res.data)
                });
            });
        },
        getOrderReviewedStatus() {
            var prefix = import.meta.env.VITE_CACHE_PREFIX as any;
            var clean_prefix = import.meta.env.VITE_CACHE_PREFIX_CLEAN as any;
            // @ts-ignore
            var key = prefix + "_" + "_order_reviewed_status";

            var clean_key = clean_prefix + "_" + "_order_reviewed_status";

            window.localStorage.removeItem(clean_key);

            return new Promise((resolve, reject) => {
                if (window.localStorage.getItem(key)) {
                    return resolve(JSON.parse(<string>window.localStorage.getItem(key)))
                }

                return request({
                    url: '/currency/getOrderReviewedStatus',
                    method: 'get',
                }).then(res => {
                    window.localStorage.setItem(key, JSON.stringify(res.data))
                    resolve(res.data)
                });
            });
        },
        getOrderInvoiceSentStatus() {
            var prefix = import.meta.env.VITE_CACHE_PREFIX as any;
            var clean_prefix = import.meta.env.VITE_CACHE_PREFIX_CLEAN as any;
            // @ts-ignore
            var key = prefix + "_" + "_order_invoice_sent_status";

            var clean_key = clean_prefix + "_" + "_order_invoice_sent_status";

            window.localStorage.removeItem(clean_key);

            return new Promise((resolve, reject) => {
                if (window.localStorage.getItem(key)) {
                    return resolve(JSON.parse(<string>window.localStorage.getItem(key)))
                }

                return request({
                    url: '/currency/getOrderInvoiceSentStatus',
                    method: 'get',
                }).then(res => {
                    window.localStorage.setItem(key, JSON.stringify(res.data))
                    resolve(res.data)
                });
            });
        },
        getOrderInvoiceStatus() {
            var prefix = import.meta.env.VITE_CACHE_PREFIX as any;
            var clean_prefix = import.meta.env.VITE_CACHE_PREFIX_CLEAN as any;
            // @ts-ignore
            var key = prefix + "_" + "_order_invoice_status";

            var clean_key = clean_prefix + "_" + "_order_invoice_status";

            window.localStorage.removeItem(clean_key);

            return new Promise((resolve, reject) => {
                if (window.localStorage.getItem(key)) {
                    return resolve(JSON.parse(<string>window.localStorage.getItem(key)))
                }

                return request({
                    url: '/currency/getOrderInvoiceStatus',
                    method: 'get',
                }).then(res => {
                    window.localStorage.setItem(key, JSON.stringify(res.data))
                    resolve(res.data)
                });
            });
        },
        getOrderInvoiceType() {
            var prefix = import.meta.env.VITE_CACHE_PREFIX as any;
            var clean_prefix = import.meta.env.VITE_CACHE_PREFIX_CLEAN as any;
            // @ts-ignore
            var key = prefix + "_" + "_order_invoice_type";

            var clean_key = clean_prefix + "_" + "_order_invoice_type";

            window.localStorage.removeItem(clean_key);

            return new Promise((resolve, reject) => {
                if (window.localStorage.getItem(key)) {
                    return resolve(JSON.parse(<string>window.localStorage.getItem(key)))
                }

                return request({
                    url: '/currency/getOrderInvoiceType',
                    method: 'get',
                }).then(res => {
                    window.localStorage.setItem(key, JSON.stringify(res.data))
                    resolve(res.data)
                });
            });
        },
        getCurrencyConfig(key: string, api: string) {
            var prefix = import.meta.env.VITE_CACHE_PREFIX as any;
            var clean_prefix = import.meta.env.VITE_CACHE_PREFIX_CLEAN as any;
            // @ts-ignore
            key = prefix + "_" + key;

            var clean_key = clean_prefix + "_" + key;

            window.localStorage.removeItem(clean_key);

            return new Promise((resolve, reject) => {
                if (window.localStorage.getItem(key)) {
                    return resolve(JSON.parse(<string>window.localStorage.getItem(key)))
                }

                return request({
                    url: '/currency/' + api,
                    method: 'get',
                }).then(res => {
                    window.localStorage.setItem(key, JSON.stringify(res.data))
                    resolve(res.data)
                });
            });
        },
        getSystemDict: (code: string) => {
            return request({
                url: `/common/dict?code=${code}`,
                method: 'get',
            })
        },
        getIndustries: (params?: object) => {
            return request({
                url: '/industries',
                method: 'get',
                params
            })
        },
        getIndustryTree() {
            return request({
                url: '/industries/tree',
                method: 'get',
            })
        },
        postIndustry: (data: object) => {
            return request({
                url: '/industries',
                method: 'post',
                data: data
            })
        },
        putIndustry: (id: number, data: object) => {
            return request({
                url: '/industries/' + id,
                method: 'put',
                data: data
            })
        },

        getDepartments: (params?: object) => {
            return request({
                url: '/departments',
                method: 'get',
                params
            })
        },
        getDepartmentTree() {
            return request({
                url: '/departments/tree',
                method: 'get',
            })
        },
        postDepartment: (data: object) => {
            return request({
                url: '/departments',
                method: 'post',
                data: data
            })
        },
        putDepartment: (id: number, data: object) => {
            return request({
                url: '/departments/' + id,
                method: 'put',
                data: data
            })
        },
        getGovUrl: (params?: object) => {
            return request({
                url: '/common/gov_url',
                method: 'get',
                params,
            });
        },
        getTaxCatalogueList: (params?: object) => {
            return request({
                url: '/common/tax_catalogue/list',
                method: 'get',
                params,
            });
        },
        getAreas: (params?: object) => {
            return request({
                url: '/areas',
                method: 'get',
                params
            })
        },
        getAreaTree() {
            return request({
                url: '/areas/tree',
                method: 'get',
            })
        },
        postArea: (data: object) => {
            return request({
                url: '/areas',
                method: 'post',
                data: data
            })
        },
        putArea: (id: number, data: object) => {
            return request({
                url: '/areas/' + id,
                method: 'put',
                data: data
            })
        },

        getJobs: (params?: object) => {
            return request({
                url: '/jobs',
                method: 'get',
                params
            })
        },
        getJobList: (params?: object) => {
            return request({
                url: '/jobs/search',
                method: 'get',
                params
            })
        },
        postJob: (data: object) => {
            return request({
                url: '/jobs',
                method: 'post',
                data: data
            })
        },
        putJob: (data: object) => {
            return request({
                // @ts-ignore
                url: '/jobs/' + data.id,
                method: 'put',
                data: data
            })
        },
        getRoles: (params?: object) => {
            return request({
                url: '/role',
                method: 'get',
                params
            })
        },
        searchRoles: (params?: object) => {
            return request({
                url: '/role/list',
                method: 'get',
                params
            })
        },
        postRole: (data: object) => {
            return request({
                url: '/role',
                method: 'post',
                data: data,
            })
        },
        getRoleMenus: (params?: object) => {
            return request({
                url: '/role-menus',
                method: 'get',
                params
            })
        },
        putRole: (id: number, data: object) => {
            return request({
                url: '/role/' + id,
                method: 'put',
                data: data,
            })
        },
        getDicts: (params?: object) => {
            return request({
                url: '/dict',
                method: 'get',
                params
            })
        },
        postDict: (data: object) => {
            return request({
                url: '/dict',
                method: 'post',
                data: data
            })
        },
        putDict: (id: number, data: object) => {
            return request({
                url: '/dict/' + id,
                method: 'put',
                data: data
            })
        },
        getUsers: (params?: object) => {
            return request({
                url: '/manager',
                method: 'get',
                params
            })
        },
        searchUsers: (params?: object) => {
            return request({
                url: '/manager/list?tag=admin',
                method: 'get',
                params
            })
        },
        putUserCheckStatus: (id: number) => {
            return request({
                url: "/user/check/" + id,
                method: 'put',
            })
        },
        putUserDisabled: (id: number, status: number) => {
            return request({
                url: "/manager/disabled",
                method: 'put',
                data: {status: status, id: id}
            })
        },
        postUpdateUser: (data: object) => {
            return request({
                // @ts-ignore
                url: '/manager/' + data.id,
                method: 'put',
                data: data
            })
        },
        postUserRoles: (data: object) => {
            return request({
                url: "/manager/role",
                method: 'post',
                data: data
            })
        },
        getUserRoles: (params?: object) => {
            return request({
                url: '/users/role',
                method: 'get',
                params
            })
        },
        postUser: (data: object) => {
            return request({
                url: '/manager',
                method: 'post',
                data: data
            })
        },
        putUserInfo: (data: object) => {
            return request({
                url: '/common/user/info',
                method: 'put',
                data: data
            })
        },
        putUserAvatar: (data: object) => {
            return request({
                url: '/common/user/avatar',
                method: 'put',
                data: data,
            })
        },
        putUserPassword: (data: object) => {
            return request({
                url: '/common/user/password',
                method: 'put',
                data: data
            })
        },
        getUserList: (params?: object) => {
            return request({
                url: '/users-list',
                method: 'get',
                params
            })
        },
        upload: (url: string, data: object) => {
            return request({
                url: url,
                method: 'post',
                data: data
            })
        },
        getProvinces: () => {
            return request({
                url: '/admin/area/list',
                method: 'get',
            });
        },
        getCities: (params?: object) => {
            return request({
                url: '/admin/area/list',
                method: 'get',
                params
            });
        },
        getBusinessManager() {
            return request({
                url: '/admin/business_manager/list',
                method: 'get',
            });
        },
        getProvince: () => {
            return request({
                url: '/province',
                method: 'get',
            })
        },
        getPermissionsTree() {
            return request({
                url: '/permission/tree',
                method: 'get',
            })
        },
        getPermissions(params?: object) {
            return request({
                url: '/permission',
                method: 'get',
                params,
            })
        },
        postPermission(data: object) {
            return request({
                url: '/permission',
                method: 'post',
                data,
            })
        },
        putPermission(id: number, data: object) {
            return request({
                url: '/permission/' + id,
                method: 'put',
                data,
            })
        },


        getManagerList(params?: object) {
            return request({
                url: '/manager/list',
                method: 'get',
                params,
            })
        },

        getMenus(params?: object) {
            return request({
                url: '/menu',
                method: 'get',
                params,
            })
        },
        postMenu(data: object) {
            return request({
                url: '/menu',
                method: 'post',
                data
            })
        },
        putMenu(id: number, data: object) {
            return request({
                url: '/menu/' + id,
                method: 'put',
                data
            })
        },
        postFile(data: object) {
            return request({
                // @ts-ignore
                url: data.url,
                method: 'post',
                data,
            });
        },
        getFilePolicy(data: object) {
            // @ts-ignore
            var auth = data.auth;
            var file_policy_key = auth + "_file_policy";
            var file_policy_time = auth + "_file_policy_expires_at";

            return new Promise((resolve, reject) => {
                if (window.localStorage.getItem(file_policy_key)) {
                    if (dayjs(window.localStorage.getItem(file_policy_time)).isAfter(dayjs())) {
                        return resolve(JSON.parse(<string>window.localStorage.getItem(file_policy_key)))
                    }
                }

                return request({
                    url: '/admin/file/policy?auth=' + auth,
                    method: 'post',
                    data
                }).then(res => {

                    window.localStorage.setItem(file_policy_key, JSON.stringify(res.data.data))
                    window.localStorage.setItem(file_policy_time, res.data.expiration)

                    resolve(res.data.data)
                });
            })
        },
        getStsToken(data: object) {
            return new Promise((resolve, reject) => {
                if (window.localStorage.getItem("sts_policy")) {
                    if (dayjs(window.localStorage.getItem("sts_policy_expires_at")).isAfter(dayjs())) {
                        return resolve(JSON.parse(<string>window.localStorage.getItem("sts_policy")))
                    }
                }

                return request({
                    url: '/file/sts',
                    method: 'post',
                    data
                }).then(res => {
                    window.localStorage.setItem("sts_policy", JSON.stringify(res.data))
                    window.localStorage.setItem("sts_policy_expires_at", res.data.origin_expiration)

                    resolve(res.data)
                });
            })
        },
        getTodoList() {
            return request({
                url: "/common/todolist",
                method: 'get',
            });
        },
        getPolicy(params?: object) {
            return request({
                url: '/common/policy',
                method: 'get',
                params
            });
        }
    };
}
