<template>
  <el-config-provider :size="getGlobalComponentSize" :locale="zhCn">
    <router-view v-show="themeConfig.lockScreenTime > 1"/>
    <LockScreen v-if="themeConfig.isLockScreen"/>
    <Setings ref="setingsRef" v-show="themeConfig.lockScreenTime > 1"/>
    <CloseFull v-if="!themeConfig.isLockScreen"/>
    <Upgrade v-if="getVersion"/>
  </el-config-provider>
</template>

<script lang="ts">
import {
  computed,
  ref,
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  onUnmounted,
  nextTick,
  defineComponent,
  watch,
  reactive,
  toRefs
} from 'vue';

import axios from 'axios'

import protobuf from '/@/proto/proto.js'

import {
  useRoute
} from 'vue-router';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import {
  storeToRefs
} from 'pinia';
import {
  useTagsViewRoutes
} from '/@/stores/tagsViewRoutes';
import {
  useThemeConfig
} from '/@/stores/themeConfig';
import other from '/@/utils/other';
import {
  Local,
  Session
} from '/@/utils/storage';
import setIntroduction from '/@/utils/setIconfont';
import LockScreen from '/@/layout/lockScreen/index.vue';
import Upgrade from '/@/layout/upgrade/index.vue';
import Setings from '/@/layout/navBars/breadcrumb/setings.vue';
import CloseFull from '/@/layout/navBars/breadcrumb/closeFull.vue';

import {
  systemApi
} from './api/system';

import mitt from '/@/utils/eventbus'

export default defineComponent({
  name: 'app',
  components: {
    LockScreen,
    Setings,
    Upgrade,
    CloseFull
  },
  setup() {
    const {
      proxy
    } = <any>getCurrentInstance();
    const setingsRef = ref();
    const route = useRoute();

    const stores = useTagsViewRoutes();
    const storesThemeConfig = useThemeConfig();

    const state = reactive({
      currentScreen: {
        width: 1024,
      },
    });

    const {
      themeConfig
    } = storeToRefs(storesThemeConfig);
    // 获取全局组件大小
    const getGlobalComponentSize = computed(() => {
      return other.globalComponentSize();
    });
    // 布局配置弹窗打开
    const openSetingsDrawer = () => {
      setingsRef.value.openDrawer();
    };
    // 设置初始化，防止刷新时恢复默认
    onBeforeMount(() => {
      // 设置批量第三方 icon 图标
      setIntroduction.cssCdn();
      // 设置批量第三方 jsf
      setIntroduction.jsCdn();
    });

    // 页面加载时
    onMounted(() => {
      nextTick(() => {
        // 监听布局配置弹窗点击打开
        proxy.mittBus.on('openSetingsDrawer', () => {
          openSetingsDrawer();
        });
        // 获取缓存中的布局配置
        if (Local.get('themeConfig')) {
          storesThemeConfig.setThemeConfig(Local.get('themeConfig'));
          document.documentElement.style.cssText = Local.get('themeConfigStyle');
        }
        // 获取缓存中的全屏配置
        if (Session.get('isTagsViewCurrenFull')) {
          stores.setCurrenFullscreen(Session.get('isTagsViewCurrenFull'));
        }
      });
    });
    // 页面销毁时，关闭监听布局配置/i18n监听
    onUnmounted(() => {
      proxy.mittBus.off('openSetingsDrawer', () => {
      });
    });

    const getVersion = computed(() => {
      let isVersion = false;

      if (route.path !== '/login') {
        // @ts-ignore
        if ((Local.get('version') && Local.get('version') !== __NEXT_VERSION__) || !Local.get('version')) isVersion = true;
      }

      // if (location.port == '9000') isVersion = false;

      return isVersion;
    });

    // 监听路由的变化，设置网站标题
    watch(
        () => route.path,
        () => {
          other.useTitle();
        }, {
          deep: true,
        }
    );


    return {
      zhCn,
      themeConfig,
      setingsRef,
      getGlobalComponentSize,
      state,
      getVersion,
    };
  },
  methods: {}
});
</script>

<style>
.sc-chat-window {
  z-index: 999;
}
</style>
