import Cookies from 'js-cookie';

/**
 * window.localStorage 浏览器永久缓存
 * @method set 设置永久缓存
 * @method get 获取永久缓存
 * @method remove 移除永久缓存
 * @method clear 移除全部永久缓存
 */
export const Local = {
    // 设置永久缓存
    set(key: string, val: any) {
        window.localStorage.setItem(key, JSON.stringify(val));
    },
    // 获取永久缓存
    get(key: string) {
        let json: any = window.localStorage.getItem(key);
        return JSON.parse(json);
    },
    // 移除永久缓存
    remove(key: string) {
        window.localStorage.removeItem(key);
    },
    // 移除全部永久缓存
    clear() {
        window.localStorage.clear();
    },
    clean(lastVersion: string) {
        let version = localStorage.getItem('version') || '1.0.0';

        version = version.replace(/"/g, '');

        function isOnlyPatchUpdated(version: string, version2: string) {
            const extract = (v: string) => {
                const match = v.match(/^[^\d]*(\d+)\.(\d+)\.(\d+)$/);

                if (!match) throw new Error(`Invalid version: ${v}`);

                return [parseInt(match[1]), parseInt(match[2]), parseInt(match[3])];
            };

            try {
                const v1 = extract(version);

                const v2 = extract(version2);

                return v1[0] === v2[0] && v1[1] === v2[1] && v1[2] !== v2[2];
            } catch (e) {
                return false;
            }
        }

        // major.minor.patch major版本号.minor次版本号 发生变化时清除全部缓存
        if (!isOnlyPatchUpdated(version, lastVersion)) {
            window.localStorage.clear();
        } else {
            // 只清除字典缓存
            Object.keys(localStorage).forEach((key) => {
                if (key == 'version' || key.indexOf('dict') > -1) {
                    localStorage.removeItem(key);
                }
            });
        }
    }
};

/**
 * window.sessionStorage 浏览器临时缓存
 * @method set 设置临时缓存
 * @method get 获取临时缓存
 * @method remove 移除临时缓存
 * @method clear 移除全部临时缓存
 */
export const Session = {
    // 设置临时缓存
    set(key: string, val: any) {
        if (key === 'token') return Cookies.set(key, val);
        window.sessionStorage.setItem(key, JSON.stringify(val));
    },
    // 获取临时缓存
    get(key: string) {
        if (key === 'token') return Cookies.get(key);
        let json: any = window.sessionStorage.getItem(key);
        return JSON.parse(json);
    },
    // 移除临时缓存
    remove(key: string) {
        if (key === 'token') return Cookies.remove(key);
        window.sessionStorage.removeItem(key);
    },
    // 移除全部临时缓存
    clear() {
        Cookies.remove('token');
        window.sessionStorage.clear();
    },
};
